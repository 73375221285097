(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/competition/assets/javascripts/competition-factory.js') >= 0) return;  svs.modules.push('/components/tipsen/competition/assets/javascripts/competition-factory.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  hooks
} = svs.components.marketplaceCompetition.services;
const {
  useCompetitionModeForPath,
  useAskForCompetitionParticipation,
  useCompetition,
  useActiveCompetitionId,
  useActiveCompetitionDetails,
  useActiveCompetition
} = hooks;
const {
  useIsPlayerInCompetition
} = hooks.useIsPlayerInCompetition;
const {
  useCanPlayInCompetitionDraw,
  useCanPlayInCompetitionProductDraw
} = hooks.useCanPlayInCompetitionDraw;
const {
  competitionsSupportedForProduct
} = svs.components.tipsen.engine.utils.competitionHelpers;
const {
  competitionHandler
} = svs.components.marketplaceCompetition.competitionHandler;
let competitionCache = {};
const defaultCompetitionHooks = {
  useAskForCompetitionParticipation: () => ({
    isPlayingCompetition: false,
    isCompetitionSignUp: false,
    competition: null,
    competitionId: null
  }),
  useCanPlayInCompetitionProductDraw: () => false,
  useCompetitionModeForPath: () => false,
  useCanPlayInCompetitionDraw: () => false,
  useIsPlayerInCompetition: () => false,
  useCompetition: () => null,
  useActiveCompetitionId: () => undefined,
  useActiveCompetitionDetails: () => undefined,
  useActiveCompetition: () => undefined
};
const getEngineDefinition = () => {
  var _svs$tipsen$data$prel, _svs$tipsen$data;
  const {
    engine
  } = (_svs$tipsen$data$prel = (_svs$tipsen$data = svs.tipsen.data) === null || _svs$tipsen$data === void 0 || (_svs$tipsen$data = _svs$tipsen$data.preloadedState) === null || _svs$tipsen$data === void 0 ? void 0 : _svs$tipsen$data.Module) !== null && _svs$tipsen$data$prel !== void 0 ? _svs$tipsen$data$prel : {
    engine: undefined
  };
  const {
    definitions
  } = svs.components.tipsen.engine;
  return definitions === null || definitions === void 0 ? void 0 : definitions[engine];
};
const getCompetitionHooks = competitionId => {
  if (competitionId) {
    const engineDefinition = getEngineDefinition();
    const competitionsSupported = engineDefinition && competitionsSupportedForProduct(engineDefinition);
    const useResponesByProxy = (valueFn, valueArgs, fallbackValue) => competitionsSupported ? valueFn(...valueArgs) : fallbackValue;
    competitionCache[competitionId] = {
      useAskForCompetitionParticipation: _ref => {
        let {
          drawNumber,
          productId,
          selectedSystemPrice,
          selectedSystemType,
          drawCount
        } = _ref;
        return useResponesByProxy(useAskForCompetitionParticipation, [{
          competitionId,
          drawNumber,
          productId,
          selectedSystemPrice,
          selectedSystemType,
          drawCount
        }], Promise.resolve({
          isPlayingCompetition: false,
          isCompetitionSignUp: false,
          competition: null,
          competitionId
        }));
      },
      useCanPlayInCompetitionProductDraw: (_ref2, alwaysDispatch) => {
        let {
          productId,
          drawNumber
        } = _ref2;
        return useResponesByProxy(useCanPlayInCompetitionProductDraw, [{
          productId,
          drawNumber
        }, competitionId, alwaysDispatch], false);
      },
      useCompetitionModeForPath: (productIds, competitionPaths) => useResponesByProxy(useCompetitionModeForPath, [competitionId, productIds, competitionPaths], false),
      useCanPlayInCompetitionDraw: (_ref3, alwaysDispatch) => {
        let {
          productId,
          drawNumber
        } = _ref3;
        return useResponesByProxy(useCanPlayInCompetitionDraw, [{
          productId,
          drawNumber
        }, competitionId, alwaysDispatch], false);
      },
      useIsPlayerInCompetition: (productId, drawNumber) => useResponesByProxy(useIsPlayerInCompetition, [productId, drawNumber, competitionId], false),
      useCompetition: () => useResponesByProxy(useCompetition, [competitionId], null),
      useActiveCompetitionId: () => useResponesByProxy(useActiveCompetitionId, [], undefined),
      useActiveCompetitionDetails: () => useResponesByProxy(useActiveCompetitionDetails, [], undefined),
      useActiveCompetition: () => useResponesByProxy(useActiveCompetition, [], undefined),
      competitionId
    };
  }
  return competitionCache[competitionId] || _objectSpread(_objectSpread({}, defaultCompetitionHooks), {}, {
    competitionId: undefined
  });
};
let createdInstance = false;
const competitionFactoryInstanceObj = {
  getCompetitionHooks: () => _objectSpread(_objectSpread({}, defaultCompetitionHooks), {}, {
    competitionId: null
  }),
  getCompetitionHooksForCms: () => _objectSpread(_objectSpread({}, defaultCompetitionHooks), {}, {
    competitionId: null
  }),
  clearCompetitionHooks: () => {
    competitionCache = {};
  }
};
const competitionFactoryInstance = {
  getCompetitionFactoryInstance: () => {
    if (!createdInstance) {
      const getCompetitionFromStore = competitionId => getCompetitionHooks(competitionId);
      competitionFactoryInstanceObj.getCompetitionHooks = competitionId => getCompetitionFromStore(competitionId);
      competitionFactoryInstanceObj.getCompetitionHooksForCms = () => {
        var _definition$drawProdu, _competitionHandler;
        const definition = getEngineDefinition();
        const productId = (definition === null || definition === void 0 || (_definition$drawProdu = definition.drawProductIds) === null || _definition$drawProdu === void 0 ? void 0 : _definition$drawProdu[0]) || undefined;
        const competitionId = ((_competitionHandler = competitionHandler()) === null || _competitionHandler === void 0 || (_competitionHandler = _competitionHandler.getActiveCompetition(productId)) === null || _competitionHandler === void 0 ? void 0 : _competitionHandler.getCompetitionId()) || undefined;
        return getCompetitionFromStore(competitionId);
      };
      createdInstance = true;
    }
    return competitionFactoryInstanceObj;
  }
};
setGlobal('svs.components.tipsen.competition', {
  competitionFactoryInstance
});

 })(window);